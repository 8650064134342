import React, { useEffect } from "react";
import BannerSection from "../Components/Home/BannerSection";
import GridSection from "../Components/Home/GridSection";
import TestimonialsandBlogSec from "../Components/Home/TestimonialsandBlogSec";
import ClientLogoCarousel from "../Components/Home/ClientLogoCarousel";
import Footer from "../Components/Layout/Footer";
import { Link, useLocation } from "react-router-dom";
import Header from "../Components/Layout/Header";
import BlogSec from "../Components/Home/BlogSec";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import getHelmetContent from "../Components/Common/HelmetData";



const Home = () => {
  useEffect(() => {
    document.title = "Think Quotient";
    AOS.init();
  }, []);

  const location = useLocation();

  const isActiveRoute = (path) => {
    return location.pathname === path;
  };

  const pageTitle = "Expert in Project OutSourcing and Technology Consulting";
  const metaTitle = "INDIA's TOP IT TRAINING COURSE.. PAY ONLY AFTER YOU ARE PLACED";
  const helmetContent = getHelmetContent();
  return (

    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="title" content={metaTitle} />
        {/* Remaining meta tag */}
        {helmetContent.meta.map((metaTag, index) => (
          <meta key={index} name={metaTag.name} content={metaTag.content} />
        ))}

        {/* scipt for facebook */}
        {helmetContent.script}

        <script>
          {`
          (function (b, m, h, a, g) {
            b[a] = b[a] || [];
            b[a].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
            var k = m.getElementsByTagName(h)[0],
              e = m.createElement(h),
              c = a != "dataLayer" ? "&l=" + a : "";
            e.async = true;
            e.src = "https://www.googletagmanager.com/gtm.js?id=" + g + c;
            k.parentNode.insertBefore(e, k);
          })(window, document, "script", "dataLayer", "GTM-TN6B4BK");
          `
          }
        </script>,
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-143016865-1"></script>,

        <script>
          {
            `
      window.dataLayer = window.dataLayer || []; 
      function gtag() { 
        dataLayer.push(arguments) 
      } gtag("js", new Date()); gtag("config", "UA-143016865-1");
       `}
        </script>,

        <script async src="https://www.googletagmanager.com/gtag/js?id=G-BSFPPN5E9D"></script>,

        <script>
          {`

        window.dataLayer = window.dataLayer || [];
        function gtag() {dataLayer.push(arguments)}
        gtag('js', new Date());

        gtag('config', 'G-BSFPPN5E9D');
        `}

        </script>,
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-574047969"></script>,

        <script>
          {`
      window.dataLayer = window.dataLayer || [];
      function gtag() { dataLayer.push(arguments) }
      gtag('js', new Date());

      gtag('config', 'AW-574047969');
      `}

        </script>


      </Helmet>
      <div className="overflow-hidden">
        <Header />

        {/* Call-to-Action Section */}
        <div className="container mx-auto text-center pt-6 md:pt-6 pb-6 hero-sec fade-in-down">
          <h2 className="md:text-3xl text-xl font-bold md:w-2/3 mx-auto">
            Empower Your Digital Footprint with <br /> ThinkQuotient for IT & AI Excellence          </h2>
          {/* <Link to="/contact">
            <button className="btn-style-1  px-10 py-3 ">Learn More</button>
          </Link> */}
        </div>

        <BannerSection />
        <GridSection />
        <TestimonialsandBlogSec />
        <BlogSec />
        <ClientLogoCarousel />
        <Footer />
      </div></>
  );
};

export default Home;
