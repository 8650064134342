import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import React from 'react';
import Slider from 'react-slick';


const KnimeVideos = () => {

  
const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    pauseOnHover: true,
    centerPadding: '0',
    dots: true, // Enable dots
    draggable: true, // Enable dragging
    swipe: true, // Enable swiping
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
        },
      },
    ],
    prevArrow: (
      <button type="button" className="slick-arrow slick-prev">
        &lt; Left
      </button>
    ),
    nextArrow: (
      <button type="button" className="slick-arrow slick-next">
        Right &gt;
      </button>
    ),
  };


 



  return (

    <div className="py-10 px-4 md:px-0">
    <div className="container mx-auto pb-4">
        <h1 className="capitalize text-center sm:col-span-2 lg:col-span-4 text-3xl sm:text-5xl xl:text-3xl font-bold pb-5">
        Tutorials
        </h1>
      
        <Slider {...settings}>
       
          <div  className="box-na mobile-box">
            <video  width="310" height="400" controls responsive title="KNIME Overview" poster="/assets/KNIMEIMG/KNIME Overview.png" style={{paddingBottom:'20px'}}>
              <source  src="/assets/knimeVideos/KNIME Overview.mp4"/>
            </video>
          </div>

          <div  className="box-na mobile-box">
            <video  width="310" height="400" controls responsive title="Read data With KNIME" poster="/assets/KNIMEIMG/read Data.png" style={{paddingBottom:'20px'}}>
            <source src="/assets/knimeVideos/Read data With KNIME.mp4"/>
            </video>
            </div>

          <div  className="box-na mobile-box">
          <video  width="310" height="400" controls  responsive title="Filter Data With KNIME" poster="/assets/KNIMEIMG/Filter Data.png" style={{paddingBottom:'20px'}}>
         <source src="/assets/knimeVideos/Filter Data With KNIME.mp4" />
            </video> </div>

          <div  className="box-na mobile-box">
          <video width="310" height="400" controls responsive title="Duplicate Row Filter" poster="/assets/KNIMEIMG/Duplicate row filter.png" style={{paddingBottom:'20px'}}>
         <source src="/assets/knimeVideos/Duplicate Row Filter.mp4" />
            </video>
          </div>

          <div  className="box-na mobile-box">
          <video  width="310" height="400" controls responsive  title="Handle Missing Value with KNIME" poster="/assets/KNIMEIMG/Missing value.png" style={{paddingBottom:'20px'}}>
         <source src="/assets/knimeVideos/Handle Missing Value with KNIME.mp4" />
            </video>
           </div>

          <div  className="box-na mobile-box">
          <video  width="310" height="400" controls responsive  title="egAggration With KNIME" poster="/assets/KNIMEIMG/Aggregation.png" style={{paddingBottom:'20px'}}>
         <source src="/assets/knimeVideos/Aggregation With KNIME.mp4" />
            </video>
          </div>

          <div  className="box-na mobile-box">
          <video  width="310" height="400" controls responsive title="Access Database in KNIME" poster="/assets/KNIMEIMG/Access database.png" style={{paddingBottom:'20px'}}>
         <source src="/assets/knimeVideos/Access Database in KNIME.mp4" />
            </video>
            </div>

          <div  className="box-na mobile-box">
          <video  width="310" height="400" controls  title="Pivot Table Within KNIME analytics platform" poster="/assets/KNIMEIMG/Pivot table.png" style={{paddingBottom:'20px'}}>
         <source src="/assets/knimeVideos/Pivot Table Within KNIME.mp4"/>
            </video>
          </div>

          <div  className="box-na mobile-box" >
            <video  width="310" height="400" controls responsive title="Flow Variables" poster="/assets/KNIMEIMG/Flow variable.png" style={{paddingBottom:'20px'}}>
              <source src="/assets/knimeVideos/Flow Variables.mp4"/>
            </video>
          </div>

          <div  className="box-na mobile-box">
            <video  width="310" height="400" controls responsive title="Filter Columns" poster="/assets/KNIMEIMG/Filter Columns.png" style={{paddingBottom:'20px'}} >
              <source src="/assets/knimeVideos/Filter Columns in KNIME.mp4"/>
            </video>
          </div>
          
          <div  className="box-na mobile-box">
            <video  width="310" height="400" controls responsive title="Value lookup node" poster="/assets/KNIMEIMG/Value lookup.png" style={{paddingBottom:'20px'}}>
              <source src="/assets/knimeVideos/Value Lookup Node.mp4"/>
            </video>
          </div>

          <div  className="box-na mobile-box">
            <video  width="310" height="400" controls responsive  title="Generic Loop" poster="/assets/KNIMEIMG/Generic loop.png" style={{paddingBottom:'20px'}}>
              <source src="/assets/knimeVideos/Generic Loop in KNIME.mp4"/>
            </video>
          </div>

          <div  className="box-na mobile-box">
            <video  width="310" height="400" controls responsive  title="Chunk Loop" poster="/assets/KNIMEIMG/Chunk loop.png" style={{paddingBottom:'20px'}}>
              <source src="/assets/knimeVideos/Chunk Loop in KNIME.mp4"/>
            </video>
          </div>
          
    </Slider>
      </div>
    </div>


  );
};

export default KnimeVideos;
