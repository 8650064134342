import React, { useEffect } from "react";
import Header from "../../Components/Layout/Header";
import ClientLogoCarousel from "../../Components/Home/ClientLogoCarousel";
import Footer from "../../Components/Layout/Footer";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import getHelmetContent from "../../Components/Common/HelmetData";
import { Helmet } from "react-helmet";
import WhyUs from "../../Components/Home/WhyUs";

const Knime = () => {
  useEffect(() => {
    document.title = "Knime - Think Quotient";
    AOS.init();
  }, []);
  const metaTitle = "Best IT Training Course with Drive Assistance";
  const helmetContent = getHelmetContent();

  return (
    
    <>
      <Helmet>
        
        <meta name="title" content={metaTitle} />
        {/* Remaining meta tag */}
        {helmetContent.meta.map((metaTag, index) => (
          <meta key={index} name={metaTag.name} content={metaTag.content} />
        ))}
        
        {/* scipt for facebook */}
        {helmetContent.script}
        <script>
          {`
          (function (b, m, h, a, g) {
            b[a] = b[a] || [];
            b[a].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
            var k = m.getElementsByTagName(h)[0],
              e = m.createElement(h),
              c = a != "dataLayer" ? "&l=" + a : "";
            e.async = true;
            e.src = "https://www.googletagmanager.com/gtm.js?id=" + g + c;
            k.parentNode.insertBefore(e, k);
          })(window, document, "script", "dataLayer", "GTM-TN6B4BK");
          `
          }
        </script>,
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-143016865-1"></script>,

        <script>
          {
            `
      window.dataLayer = window.dataLayer || []; 
      function gtag() { 
        dataLayer.push(arguments) 
      } gtag("js", new Date()); gtag("config", "UA-143016865-1");
       `}
        </script>,

        <script async src="https://www.googletagmanager.com/gtag/js?id=G-BSFPPN5E9D"></script>,

        <script>
          {`

        window.dataLayer = window.dataLayer || [];
        function gtag() {dataLayer.push(arguments)}
        gtag('js', new Date());

        gtag('config', 'G-BSFPPN5E9D');
        `}

        </script>,
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-574047969"></script>,

        <script>
          {`
      window.dataLayer = window.dataLayer || [];
      function gtag() { dataLayer.push(arguments) }
      gtag('js', new Date());

      gtag('config', 'AW-574047969');
      `}

        </script>
        
      </Helmet>
    <div>
      {/* Header Section */}
      <Header />

      {/* Banner Section */}
      <div>
        <div className="knime-banner-sec flex items-center">
          <div className="container text-left text-white mx-auto">
            <div
              className="md:w-1/2 mx-4 md:mx-0"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <h1 className="text-2xl md:text-4xl font-semibold mb-4 ">
                Unlocking Data's Potential
              </h1>
              <p className="text-lg mb-8">
                Transform Your Insights with KNIME Technology
              </p>
              <Link to="/contact?scrollTo=contact-form">
                <button className="btn-style-2 px-6 py-2 ">
                  Get In Touch With Us
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="container mx-auto md:py-20 py-10 px-2">
          <div
            className="text-black text-2xl md:text-5xl font-bold text-center md:w-1/2 mx-auto"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            Let ThinkQuotient Help You Create Your
            <span className="about-us-banner-txt"> Online Presence</span>
          </div>
        </div>
      </div>

      <div className="flex flex-col items-center py-14 my-10  bg-gray-100 fade-in-down px-6">
        <h1 className="text-lg font-normal container mx-auto"
           data-aos="fade-right"
           data-aos-duration="1000"
        >
          <b>
            Our experienced consultants are here to provide strategic insights,
            innovative solutions, and cutting-edge technology guidance.{" "}
          </b>{" "}
          <br /> Whether you're looking to optimize your IT infrastructure,
          leverage emerging technologies, or conquer complex digital challenges,
          ThinkQuotient is your trusted ally. Let's embark on a transformative
          journey together, where technology becomes your competitive advantage.
          Welcome to a future empowered by technology with ThinkQuotient.
        </h1>
      </div>

      {/* Floating Divs */}
      <div
        className="container mx-auto  mb-10"
       
      >
        <div className="mt-12 text-center">
          <h1 className="text-xl md:text-2xl font-bold md:w-2/3 mx-auto">
            A Customer-Centric Approach To Bringing Innovations To Your Business
          </h1>
        </div>
        <div className="mt-12 md:flex flex-wrap items-center justify-center space-y-8 md:space-y-0 md:space-x-2">
          {/* First style  */}
          <div className="w-full md:w-[24%]">
            <div className="  justify-center flex ">
              <div className="h-24 w-24 bg-gray-200 rounded-full  flex items-center justify-center ">
                <img
                  src="/assets/images/font-1.svg"
                  className="h-16 w-16 rounded-full items-center"
                />
              </div>
            </div>
            <div className="mt-8 pb-12 bg-gray-200 rounded-md shadow-md p-8 mx-6 md:mx-0  md:ml-4">
              <h3 className="font-bold w-4/6">
                You Reach Out to ThinkQuotient with Your Vision
              </h3>
              <p className="mt-4 text-[14px] font-normal leading-relaxed w-5/6">
                Transforming visions into mobile reality. We specialize in
                crafting Android, iOS, & cross-platform apps tailored to your
                business needs. Elevate your brand with our user-centric mobile
                solutions.
              </p>
            </div>
          </div>

          {/* Second style  */}
          <div className="w-full md:w-[24%]">
            <div className="  justify-center flex ">
              <div className="h-24 w-24 bg-gray-200 rounded-full  flex items-center justify-center ">
                <img
                  src="/assets/images/font-2.svg"
                  className="h-16 w-16 rounded-full items-center"
                />
              </div>
            </div>
            <div className="mt-8 pb-12 bg-gray-200 rounded-md shadow-md p-8 mx-6 md:mx-0  md:ml-4">
              <h3 className="font-bold  w-8/12">
                We Analyze Your Requirements & Craft a Tailored Technology
                Strategy
              </h3>
              <p className="mt-4 text-sm leading-relaxed font-normal w-5/6">
                Leveraging our expertise and industry insights, we meticulously
                analyze your technology needs. Subsequently, we create a
                customized technology strategy that aligns precisely with your
                business objectives.
              </p>
            </div>
          </div>

          {/* Third style  */}
          <div className="w-full md:w-[24%]">
            <div className="justify-center flex ">
              <div className="h-24 w-24 bg-gray-200 rounded-full  flex items-center justify-center ">
                <img
                  src="/assets/images/font-3.svg"
                  className="h-16 w-16 rounded-full items-center"
                />
              </div>
            </div>
            <div className="mt-8 pb-12 bg-gray-200 rounded-md shadow-md p-8 mx-6 md:mx-0  md:ml-4">
              <h3 className="font-bold w-10/12">
                Execution Begins on Deliverables
              </h3>
              <p className="mt-4 text-sm font-normal leading-relaxed w-4/5">
                With the plan in place, ThinkQuotient's team of skilled
                professionals springs into action. We initiate the execution
                phase, utilizing our expertise in technology consulting and
                innovation.
              </p>
            </div>
          </div>

          {/* Fourth style  */}
          <div className="w-full md:w-[24%]">
            <div className="  justify-center flex ">
              <div className="h-24 w-24 bg-gray-200 rounded-full  flex items-center justify-center ">
                <img
                  src="/assets/images/font-4.svg"
                  className="h-16 w-16 rounded-full items-center"
                />
              </div>
            </div>
            <div className="mt-8 pb-24 bg-gray-200 rounded-md shadow-md ml-4 p-8 mr-6">
              <h3 className="font-bold  w-8/12">
                Precisely Delivered Technology Solutions
              </h3>
              <p className="mt-4 text-sm font-normal w-5/6 leading-relaxed">
                Your satisfaction is our ultimate goal. We deliver the
                technology solutions precisely as you envisioned, ensuring they
                seamlessly integrate with your business operations & objectives.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>

      <WhyUs/>
      <ClientLogoCarousel />
      <Footer />
    </div>
    </>
  );
};

export default Knime;
